import React from "react"
import axios from "axios"
import "./Subscribe.scss"

export default function Subscribe(props) {
  const [email, setEmail] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [showSent, setShowSent] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      document.body.classList.add("hide-intercom")
    }, 100)
    return () => {
      document.body.className = document.body.className.replace(
        "hide-intercom",
        ""
      )
    }
  }, [])

  const subscribe = async e => {
    e.preventDefault()
    if(isLoading) return
    setIsLoading(true)
    try {
      await axios.post(
        `${process.env.GATSBY_SERVER_URL}/subscribe/launch-campaign`,
        {
          email,
        }
      )
    } catch (error) {
      console.error(error.getMessage?.() || error)
    } finally {
      setIsLoading(false)
      setEmail("")
      setShowSent(true)
      setTimeout(() => {
        setShowSent(false)
      }, 1000)
    }
  }

  const disabled = !email || isLoading

  return (
    <div>
      <form className="subscribe-form" onSubmit={subscribe}>
        <input
          className="form-control"
          required
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <button type="submit" className="btn btn-primary" disabled={disabled}>
          {showSent
            ? "Sent!"
            : isLoading ? "Loading..." : "Subscribe"
          }

        </button>
      </form>
    </div>
  )
}
